import { Fancybox } from "@fancyapps/ui";
import select2 from 'select2'

//smooth scroll
$('.anchor').click(function(e){
	e.preventDefault();
	const to_anchor = $(this).attr('href');
	$([document.documentElement, document.body]).animate({
		scrollTop: $(to_anchor).offset().top
	}, 1000);
});

$('#rememberme').on('change', function(){
	const state = $(this).prop('checked')
	const label = $(this).closest('label')
	if(state)
		label.addClass('checked')
	else
		label.removeClass('checked')
})

//login page password view
const password_input = $('#user_pass')
if(password_input.length){
	const btn = $('<button class="show_password_login" type="button"><svg id="eye-openned" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg><svg id="eye-closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg></button>')
		.on('click', (e)=> toggle_password_visibility(e))
	password_input.closest('p.login-password').append(btn)
}else{
	$('.show_password_login').on('click', (e) => toggle_password_visibility(e))
}
function toggle_password_visibility(e){
	if(!$(e.currentTarget).hasClass('showing')){
		$(e.currentTarget).addClass('showing')
		$(e.currentTarget).prev('input').attr('type', 'text')
	}else{
		$(e.currentTarget).removeClass('showing')
		$(e.currentTarget).prev('input').attr('type', 'password')
	}
}

$('#upload-tag-select').select2({
	placeholder: $(this).data('placeholder'),
	dropdownParent: $('#upload-images-modal'),
	maximumSelectionLength: 3
})

let imagesArray = []
$('#upload-images-input').on('change', (e) => {
	const files = e.currentTarget.files
	for (let i = 0; i < files.length; i++) {
		imagesArray.push(files[i])
	}
	displayImages()
})

$(document).on('click', '.delete-preview-image', (e) => {
	e.preventDefault()
	const index = $(e.currentTarget).closest('.is-image').index()
	imagesArray.splice(index, 1)
	displayImages()
})

function displayImages(){
	$('.preview-images > .is-image:not(.add-image)').remove()
	const add_btn = $('.preview-images > .is-image.add-image')
	const newImage = $('<div></div>')
		.addClass('is-image')
		.append($('<div class="preview-image-wrapper"><img alt="Preview image" /></div>'))
		.append($('<span class="delete-preview-image"><svg fill="#fff" xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></span>'))

	imagesArray.forEach((image, index) => {
		let element = newImage.clone()
		element.find('img').attr('src', URL.createObjectURL(image))
		element.insertBefore(add_btn)
	})
}

$('#upload-modal-btn').on('click', (e) => {
	const upload_errors = $('.upload-errors')
	const upload_success = $('.upload-success')
	upload_errors.text('')
	upload_success.text('')
	if(!imagesArray.length){
		upload_errors.text(upload_errors.data('message-invalid-images'))
		return
	}

	//disable btn
	$(e.currentTarget).prop('disabled', true).append('<div class="lds-dual-ring"></div>')

	let form_data = new FormData()
	for(let i=0; i < imagesArray.length; i++){
		form_data.append('file[]', imagesArray[i])
	}
	form_data.append('action', 'user_images_upload')
	form_data.append('tags', $('#upload-tag-select').val())
	form_data.append('album', current_album_term_id[0])
	form_data.append('nonce', ajax_media_upload_nonce)

	$.ajax({
		url: ajaxurl,
		type: 'POST',
		contentType: false,
		processData: false,
		data: form_data,
		complete: (response) => {
			response = JSON.parse(response.responseText)
			if(response.success){ //if some succeeded
				let text = ''
				imagesArray = imagesArray.filter(file => response.errors.some(error => error.file.name === file.name))
				displayImages()
				upload_success.text(upload_success.data('message').replace(/%d/g, response.success))
			}

			if(response.errors.length){ //if fails
				let text = ''
				response.errors.forEach((error, index) => text += (index > 0 ? '<br>':'') + error.error + ': '+error.file.name)
				upload_errors.html(text)
				//reenable button
				$(e.currentTarget).prop('disabled', false).find('.lds-dual-ring').remove()
				return
			}

			//remove images
			imagesArray = []
			displayImages()
			//reenable button
			$(e.currentTarget).prop('disabled', false).find('.lds-dual-ring').remove()
			upload_errors.text('')
			upload_success.text('')

			Fancybox.close()

			Fancybox.show([
					{
						src: $('#thx-upload-modal')[0],
						type: "inline",
					}
				],
				{
					autoStart: false,
					backdropClick: false,
					dragToClose: false,
					keyboard: {}
				})

		}
	})
})

Fancybox.bind('.upload-modal', {
	id: 'upload-images-modal',
	defaultType: 'html',
	backdropClick: false,
	dragToClose: false,
	keyboard: {}
})

$(document).on('click', '#reupload-images', () => {
	Fancybox.close()
	$('[data-src="#upload-images-modal"]').eq(0).click()
})

$('.sm-menu li.has-submenu > a').on('click', function(e){
	e.preventDefault()
	$(this).closest('.has-submenu').toggleClass('openned')
})

Fancybox.bind('[data-fancybox="photo-gallery"]', {
	Toolbar: {
		display: {
			left: ["infobar"],
			middle: [],
			right: ["download", "thumbs", "close"],
		},
	},
})

$(document).on('click', '.downloadable-image', function(){
	const src = $(this).siblings('a').attr('href')
	const filename = src.substring(src.lastIndexOf('/')+1)
	const link = document.createElement('a');
	link.href = src;
	link.download = filename;

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
})

function addOrRemoveElement(arr, element) {
	var index = arr.indexOf(element);
	if (index !== -1) {
		arr.splice(index, 1);
	} else {
		arr.push(element);
	}
	return arr;
}

var selected_images = []
var is_active_selectable = false

$(document).on('change', '.selectable-toggler input', (e) => {
	is_active_selectable = $(e.currentTarget).prop('checked')
	if(is_active_selectable){
		$('.selectable-toggler').addClass('is-active')
		$('.selectable-image').show()
		$('.downloadable-image').hide()
		$('#multi-select-popup').show()
		$('#download-zip-wrapper').css('display', 'flex')
	}else{
		$('.selectable-toggler').removeClass('is-active')
		$('.selectable-image').hide()
		$('#download-zip-wrapper').hide()
		deactivate_selected_images()
	}
})
$(document).on('click', '#cancel-download-zip', (e) => {
	$('.selectable-toggler input').click()
})
$(document).on('change', '.selectable-image input', (e) => {
	addOrRemoveElement(selected_images, $(e.currentTarget).val())

	$('#download-zip .total').text(selected_images.length)

	if(selected_images.length){
		$('#download-zip').show()
	}else{
		$('#download-zip').hide()
	}
})

function deactivate_selected_images(){
	const btn = $('#download-zip')
	$('.selectable-image input').prop('checked', false)
	$('.downloadable-image').show()
	$('#multi-select-popup').hide()
	selected_images = []
	btn.prop('disabled', false).hide()
	btn.find('.lds-dual-ring').remove()
}

var is_generating_zip = false
$('#download-zip').on('click', (e) => {
	if(is_generating_zip) return;

	is_generating_zip = true
	$(e.currentTarget).prop('disabled', true).append('<div class="lds-dual-ring"></div>')

	$.ajax({
		url: ajaxurl,
		type: 'POST',
		xhrFields:{
			responseType: 'blob'
		},
		data: {
			action: 'generate_zip_file',
			nonce: ajax_generate_zip_file_nonce,
			ids: selected_images,
		},
		success: (blob) => {
			//console.log(blob)
			const filename = 'images.zip'
			let a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display:none";
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = filename;
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();

			is_generating_zip = false
			deactivate_selected_images()
		}
	})
})

$('#multi-select-popup .close-popup').on('click', function(){
	$('#multi-select-popup').remove()
})

//load more images
if($('#photo-results').length){
	var is_loading_more_images = false
	var is_complete_more_images = false
	$(window).scroll(function() {
		if($(window).scrollTop() >= $(document).height() - $(window).height() - 200) {
			if(is_loading_more_images || is_complete_more_images) return;

			const next_page = parseInt($('#current_page_index').val()) + 1

			const current_album_term_id = parseInt($('#current_album_term_id').val())
			const current_tag_term_id = parseInt($('#current_tag_term_id').val())
			const current_year = parseInt($('#current_year').val())

			is_loading_more_images = true
			$('#load-more-spinner').css('visibility', 'visible')
			$.post(ajaxurl, {
				"action": "load_more_images",
				"page": next_page,
				"current_album_term_id": current_album_term_id,
				"current_tag_term_id": current_tag_term_id,
				"current_year": current_year,
			}, (response) => {
				if(response !== 'complete'){
					$('#current_page_index').val(next_page)
					$('#photo-results').append(response)
				}
				if(response === 'complete')
					is_complete_more_images = true
				$('#load-more-spinner').css('visibility', 'hidden')
				is_loading_more_images = false
			})
		}
	})
}

move_footer()
window.onresize = function(){
	move_footer()
	toggle_filter_submenu()
}
function move_footer(){
	if(window.innerWidth <= 1000){
		$('footer.lb-sidemenu-footer').appendTo($('#main-content'))
		$('#multi-select-popup').appendTo($('body'))
	}else{
		$('footer.lb-sidemenu-footer').appendTo($('.lb-sidemenu'))
		$('#multi-select-popup').appendTo($('.lb-sidemenu .sm-title'))
	}
}

toggle_filter_submenu()
function toggle_filter_submenu(){
	const li = $('.li-filter')
	if(window.innerWidth > 1000){
		if(li.hasClass('has-preselected-year'))
			li.addClass('openned')
	}else{
		li.removeClass('openned')
	}
}
